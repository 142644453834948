import gql from "graphql-tag";

export const TRAVELS = gql`
  query filterWithPaginateTravels($criteria: TravelFilterCriteria!, $pagination: Pagination!) {
    filterWithPaginateTravels(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
      }
      content {
        id
        requester {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
        }
        employee {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
        }
        beginDate
        endDate
        startWorkDate
        isDomestic
        address
        country
        city
        region
        travelDescription
        reason
        requestStatus
        leaveRequest {
          id
          requester {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          leaveType {
            id
            name
          }
          leaveRequestDays {
            date
            duration
            period
            fromTime
          }
          leaveRequestStatus
        }
        expenses {
          id
          name          
          requestStatus
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          abolisher {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          allRequested {
            amount
            code
          }
          allApproved {
            amount
            code
          }
        }
        imprests {
          id
          period
          timestamp
          requestStatus
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          abolisher {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          requested {
            amount
            code
          }
          approved {
            amount
            code
          }
        }
        debits {
          id
          asset {
            id
            name
            assetCategory {
              id
              name
            }
            assetModel {
              id
              name
            }
            serialNumber
          }
          borrowDate
          dueDate
          giveBackDate
          quantity
        }
        travelRequestProcesses {
          id
          approver {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          approved
          note
          eventDateTime
          nextApprover
        }
        travelDetails {
          id
          travelDetailType
          travelDetailSubType
          beginDate
          endDate
          isRentCar
          isShuttle
          comment
        }
        subsistences {
          id
          period
          timestamp
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
          }
          requested {
            amount
            code
          }
        }
        project {
          id
          name
          color
        }
        attrition
      }
    }
  }
`;
